<template>
  <v-app>
    <router-view></router-view>    
    <customizer/>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Customizer from '../../layouts/common-drawer/Customizer.vue';

export default {
  components: {
    Customizer: () => import("@/layouts/common-drawer/Customizer"),
  },
  data() {
    return {
      layoutName: "",
    };
  },
  computed: {
    ...mapGetters(["getThemeMode"]),
  },
  // mounted() {
  //   if (this.$route.params.layout) {
  //     this.changeThemeLayout(this.$route.params.layout);
  //   }
  // },
  methods: {
    ...mapActions(["changeThemeLayout"]),
  },
};
</script>
